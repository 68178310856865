import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as Robot_2 } from "../../../../assets/robots/robot_2.svg";
import { ReactComponent as Robot_3 } from '../../../../assets/robots/robot_3.svg';
import { ReactComponent as Robot_4 }  from '../../../../assets/robots/robot_4.svg';

import { useStyles } from './styles';

const taticalOutReaches = [
  {
    image: <Robot_2 />,
    reachTitle: "Marketing Emails",
    reachDescription: "Powerful one-touch emails and campaigns.",
    callMadeColor: "#262C37",
    firstTypoColor: "#262C37",
    activeStep: 1,
  },

  {
    image: <Robot_3 />,
    reachTitle: "Sales Emails",
    reachDescription: "Persuasive emails for leads and customers.",
    callMadeColor: "#262C37",
    firstTypoColor: "#262C37",
    activeStep: 2,
  },

  {
    image: <Robot_4 />,
    reachTitle: "Product Emails",
    reachDescription: "Effective user onboarding and feedback emails.",
    callMadeColor: "#262C37",
    firstTypoColor: "#262C37",
    activeStep: 3,
  },
];

const TacticalOutreachRef = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.group}>
      {taticalOutReaches.map((item, index) => (
        <Grid
          item
          xs={12}
          sm={4}
          key={index}
          className={classes.item}
          onClick={() => history.push(`/tactical-outreach?tab=${item.activeStep}`)}
        >
          <Paper
            className={classes.container}
          >
            <ArrowForwardIcon
              style={{
                color: item.callMadeColor,
                transform: "rotate(-45deg)",
                // cursor: 'pointer'
              }}
            />

            <Box className={classes.image}>
              {item.image}
            </Box>
            {/* <img className={classes.image} src={item.image} /> */}

            <Box className={classes.content}>
              <Typography
                className={classes.title}
                style={{ color: item.firstTypoColor }}
              >
                {item.reachTitle}
              </Typography>
              <Typography className={classes.description}>
                {item.reachDescription}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default TacticalOutreachRef;
