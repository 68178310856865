import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from '../../../../../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../../../../../assets/icons/clock_icon.svg';
import { convertHTMLToText, convertMarkdownToText, isHTML } from "../../../../helpers";
import { ReactComponent as ReloadIcon } from '../../../../../../assets/icons/reload_icon.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit_dark.svg';
import { CampaignStatuses, CampaignSubCategory } from "../../../../../../interfaces/campaign";

import { useStyles } from "./styles";

const EmailsList = ({
  campaign,
  email,
  setEmail,
  setUnsavedDataModal,
  setLoading,
  handleGetCampaign,
  isSaveDisabled
}) => {
  const history = useHistory();
  const classes = useStyles();

  const userTimezone = moment.tz.guess();

  return (
    <>
      {campaign.emails.map((item, index) => (
        <Box
          key={index}
          className={`${classes.sideBarItem} ${email.id === item.id ? classes.sideBarItemActive : ''}`}
          onClick={() => {
            if (!isSaveDisabled()) {
              setUnsavedDataModal({
                active: true,
                targetEmail: item
              })
            } else {
              if ('status' in item) {
                if (item.status === 'READY') {
                  setEmail(item);
                }
              } else {
                setEmail(item);
              }
            }
          }}
          style={{
            position: 'relative',
            opacity: item.status === 'PENDING' ? '0.5' : '1',
            pointerEvents: item.status === 'PENDING' ? 'none' : 'all',
            boxShadow: item.status === 'ERROR' ? '-2px 0 0 0 #DC2626' : 'none',
            cursor: 'status' in item
              ? item.status === 'READY'
                ? 'pointer'
                : 'default'
              : 'pointer'
          }}
        >
          {item.status === 'PENDING' && (
            <CircularProgress
              color="inherit"
              style={{
                width: '30px',
                height: '30px',
                position: 'absolute',
                top: '30%',
                left: '42%',
              }}
            />
          )}
          {item.status === 'ERROR' && (
            <Button
              color="secondary"
              variant="contained"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: '100px solid rgba(0, 0, 0, 0.2)'
              }}
              startIcon={<ReloadIcon style={{ color: 'black' }} />}
              onClick={() => {
                setLoading(true);
                handleGetCampaign(campaign.id, email.id);
              }}
            >
              Refetch
            </Button>
          )}
          <Box style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
            <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
              {item.subject?.trim()?.length ? item.subject : '—'}
            </Typography>
            {campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS && campaign.status === CampaignStatuses.COMPLETED && item.status === 'READY' && (
              <IconButton
                size="small"
                onClick={() => {
                  history.push(`/tactical-outreach/builder?campaign_id=${campaign.id}&target_email_id=${email.id}`)
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
          <Typography className={classes.sideBarItemContent}>
            {isHTML(item.content)
              ? convertHTMLToText(item.content)
              : convertMarkdownToText(item.content)
            }
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', }}>
            <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', marginRight: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
              <CalendarIcon />
              {item.updatedAt?.trim()?.length ? moment.utc(item.updatedAt).tz(userTimezone).format('DD-MMM-YYYY') : '—'}
            </Typography>
            <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
              <ClockIcon style={{ color: '#475569' }} />
              {item.updatedAt?.trim()?.length ? moment.utc(item.updatedAt).tz(userTimezone).format('HH:mm') : '—'}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default EmailsList;
