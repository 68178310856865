import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { addPaymentMethod, createOrder, getCurrentSubscription, getSubscriptions, renewSubscription } from "../../redux/services/subscriptions";
import { ReactComponent as FlexUserMainIcon } from '../../assets/icons/flex_user_main_icon.svg';
import { ReactComponent as FlexUserBenefitIcon } from '../../assets/icons/flex_user_benefit.svg';
import { ReactComponent as ProfesionalMainIcon } from '../../assets/icons/professional_main_icon.svg';
import { ReactComponent as ProfesionalBenefitIcon } from '../../assets/icons/professional_benefit.svg';
import { ReactComponent as StartupMainIcon } from '../../assets/icons/startup_main_icon.svg';
import { ReactComponent as StartupBenefitIcon } from '../../assets/icons/startup_benefit.svg';
import { ReactComponent as PlanBenefitCheckmarkIcon } from '../../assets/icons/plan_benefit_checkmark.svg';
import { ReactComponent as PlanBenefitPlusIcon } from '../../assets/icons/plan_benefit_plus.svg';
import { ReactComponent as PlanBenefitCrossIcon } from '../../assets/icons/plan_benefit_cross.svg';
import { ReactComponent as ButtonBackIcon } from '../../assets/icons/button_back.svg';
import PaymentModal from "../../components/main/LimitationWidget/components/PaymentModal";
import { useHistory } from "react-router-dom";
import { getCurrencySybmol, formatCurrency } from "../../helpers/curency";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { boldWordInSentence } from "../../helpers/text";
import { getProfile } from "../../redux/services/profile";
import { notify } from "../../providers/notification";

import { useStyles } from "./styles";
import { AccountSubscriptionResponse } from "../../interfaces/subscriptions";

const highlightedWords = [
  '10% off',
  'Up to 20',
  'Tactical',
  'Max',
  'WhatsApp',
  'Custom-trained',
  'Centralised library',
  'Team',
  'Unlimited',
  'Performance',
  'Dedicated',
  'Company-wide',
  'Advanced',
  'Share & earn',
  'Centralised',
  'WhatsApp group',
  'Bundled',
  'Limited',
  'Early-access',
  'Custom-trained',
  'Free',
  'Everything',
  'History'
]

interface SubscriptionMap {
  id: string;
  name: string;
  description: string;
  price: number;
  level: number;
  currency: string;
  uniqueKey: string;
  isActive: boolean;
  properties: any;
  benefit:any;
  colors: any;
  disabled: boolean;
  icon: any;
  oldPrice: number;
  order: number;
}

const Subscriptions = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionMap[]>(null);
  const [currentSubscription, setCurrentSubscription] = useState<AccountSubscriptionResponse | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState<{ active: boolean, order: any }>({
    active: false,
    order: null
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetSubscriptions();
    handleGetCurrentSubscription();
  }, []);

  const handleGetSubscriptions = async () => {
    const { data, error } = await getSubscriptions();

    if (data && !error) {
      setSubscriptions(
        data.map(item => (
          {
            ...item,
            ...getSubscriptionMetadata(item)
          }
        ))
      );
    }
  }

  const getSubscriptionMetadata = (item) => {
    let metadata = null;

    switch (item.level) {
      case 1:
        metadata = {
          colors: {
            text: '#262C37',
            background: '#FFFFFF',
            border: '#E9EAEB',
            checkmark: '#197D42',
            cross: '#64748B',
            plus: '#CAB354',
            description: '#50555E'
          },
          icon: <FlexUserMainIcon />,
          benefit: {
            icon: <FlexUserBenefitIcon />,
            text: '14 days free',
            textColor: '#262C37',
            background: '#FFFFFF'
          },
          oldPrice: 0,
          disabled: !item.isActive,
          order: item.level,
        };
        break;
      case 2:
        metadata = {
          colors: {
            text: '#262C37',
            background: '#F5FDFC',
            border: '#0C9587',
            checkmark: '#197D42',
            cross: '#64748B',
            plus: '#CAB354',
            description: '#50555E'
          },
          icon: <ProfesionalMainIcon />,
          benefit: {
            icon: <ProfesionalBenefitIcon />,
            text: 'First 1000 users',
            textColor: '#197D42',
            background: '#DAF8E6'
          },
          oldPrice: 295,
          disabled: !item.isActive,
          order: item.level,
        };
        break;
      case 3:
        metadata = {
          colors: {
            text: '#262C37',
            background: '#FFFFFF',
            border: '#E9EAEB',
            checkmark: '#197D42',
            cross: '#64748B',
            plus: '#CAB354',
            description: '#50555E'
          },
          icon: <StartupMainIcon />,
          benefit: {
            icon: <StartupBenefitIcon />,
            text: 'Billed annually',
            textColor: '#262C37',
            background: '#FFFFFF'
          },
          oldPrice: 0,
          disabled: !item.isActive,
          order: item.level,
        };
        break;
      default:
        break;
    }

    return metadata;
  }

  const handleCreateOrder = async (item) => {
    setLoading(true);

    const { data: profile, error: profileError } = await getProfile(null, true);

    if (profile && !profileError) {
      const { data, error } = await createOrder({ id: item.id, productType: 'SUBSCRIPTION' });

      if (data && !error) {
        setShowPaymentModal({
          active: true,
          order: {
            ...data,
            name: item.name
          }
        });
      } else {
        notify.error('Something went wrong');
      }
    } else {
      notify.error('You have to be logged in to procceed this action');
    }

    setLoading(false);
  }

  const handleRenewSubscription = async () => {
    setLoading(true);

    const { error } = await renewSubscription();

    if (!error) {
      notify.success('Subscription has been renewed');

      setTimeout(() => {
        history.push('/dashboard');
      }, 1000);
    } else if (error?.status === 402) {
      handleChangePaymentMethod();
    } else {
      notify.error('Something went wrong. Try again later');

      setLoading(false);
    }
  }

  const handleGetCurrentSubscription = async () => {
    const url = window.location.pathname;

    const { data, error } = await getCurrentSubscription(null, true);

    if (url.includes('/choose-your-plan') && data && !error) {
      history.push('/subscriptions');
    } else {
      setCurrentSubscription(data);
    }
  }

  const handleChangePaymentMethod = async () => {
    setLoading(true);
  
    const { data, error } = await addPaymentMethod();
  
    if (data && !error) {
      setShowPaymentModal({
        active: true,
        order: {
          ...data,
          name: 'Add payment method',
          renewSubscriptionAfter: true
        }
      })
    } else {
      notify.error('Something went wrong. Try again later or contact our support');
    }
  
    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        {history.location.pathname.includes('/subscriptions') && currentSubscription && (
          <IconButton
            size="small"
            style={{
              position: 'absolute',
            }}
            onClick={() => history.push('/dashboard')}
          >
            <ButtonBackIcon />
          </IconButton>
        )}
        <Typography style={{ textAlign: 'center', fontSize: '48px', fontWeight: '600', lineHeight: '57px' }}>
          Choose what fits you best
        </Typography>
        <Typography style={{ width: '676px', textAlign: 'center', margin: '0 auto', fontSize: '18px', fontWeight: '500', lineHeight: '27px', color: '#475569' }}>
          Pebbles Ai automates your B2B go-to-market workflow with a single platform that houses continuously learning AI agents.
        </Typography>
        <Box className={classes.plans}>
          {!subscriptions
            ? <Backdrop
              style={{ color: '#fff', zIndex: 9999 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            : subscriptions.map((item, index) => (
              <Box
                key={index}
                className={classes.planCard}
                style={{
                  background: item.colors.background,
                  borderColor: item.colors.border,
                  filter: item.disabled ? 'opacity(0.5)' : 'none',
                  opacity: item.disabled ? '0.5' : '1',
                }}
              >
                {index === 1 && (
                  <Typography style={{ position: 'absolute', top: '0', left: '0', textAlign: 'center', width: '100%', padding: '6px 0px', background: '#DAF8E6', fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#197D42' }}>
                    BEST VALUE
                  </Typography>
                )}
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '12px', fontSize: '24px', fontWeight: '600', lineHeight: '28px', color: item.colors.text }}>
                  {item.icon}
                  {item.name}
                </Typography>
                <Typography style={{ marginTop: '-25px', display: 'flex', alignItems: 'flex-end', fontSize: '48px', fontWeight: '600', lineHeight: '48px', color: item.colors.text }}>
                  {!!item.oldPrice && (
                    <Typography style={{ position: 'relative', fontSize: '36px', fontWeight: '400', lineHeight: '36px', color: '#6E737D' }}>
                      {item.oldPrice}
                      <Box style={{ position: 'absolute', top: '50%', transform: 'translate(-8px, 0px) rotate(-8deg)', width: '130%', height: '2px', background: '#6E737D' }} />
                    </Typography>
                  )}
                  &nbsp;
                  <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '75px', color: item.colors.description }}>
                    {getCurrencySybmol(item.currency)}
                  </Typography>
                  {item.price}
                  <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: item.colors.description }}>
                    /mth.
                  </Typography>
                </Typography>
                <Box 
                  className={classes.benefitTag}
                  style={{
                    background: item.benefit.background
                  }}
                >
                  {item.benefit.icon}
                  <Typography style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: item.benefit.textColor }}>
                    {item.benefit.text}
                  </Typography>
                </Box>
                <Typography style={{ marginTop: '16px', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: item.colors.description }}>
                  {item.description}
                </Typography>
                <Box className={classes.benefitsList}>
                  {[...item.properties].sort((a, b) => a.order - b.order).map((prop, index) => (
                    <Typography
                      key={index}
                      style={{ width: '46%', display: 'flex', alignItems: 'center', gap: '12px', fontSize: '14px', fontWeight: '500', lineHeight: '16px', color: item.colors.description }}
                    >
                      {prop.relationType === 'INCLUDED'
                        ? <PlanBenefitCheckmarkIcon
                          style={{
                            minWidth: '15px',
                            minHeight: '11px',
                            color: item.colors.checkmark
                          }}
                        />
                        : prop.relationType === 'EXCLUDED'
                          ? <PlanBenefitCrossIcon
                            style={{
                              minWidth: '12px',
                              minHeight: '12px',
                              color: item.colors.cross
                            }}
                          />
                          : prop.relationType === 'ADDITIONAL'
                            ? <PlanBenefitPlusIcon
                              style={{
                                minWidth: '13px',
                                minHeight: '13px',
                                color: item.colors.plus
                              }}
                            />
                            : null
                      }
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          margin: '0px'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: boldWordInSentence(
                            prop.name,
                            highlightedWords.find(item => {
                              const regex = new RegExp(`\\b${item}\\b`, 'g');
                              const matches = prop.name.match(regex);

                              if (matches) {
                                return matches;
                              } else {
                                return null;
                              }
                            })
                          )
                        }}
                      />
                    </Typography>
                  ))}
                </Box>
                <Button
                  fullWidth
                  color={'primary'}
                  variant={'contained'}
                  disabled={
                    item.disabled ||
                    currentSubscription
                      ? getCurrentSubscriptionStatus(currentSubscription) === 'ACTIVE' ||
                        item.order !== subscriptions?.find(item => item.id === currentSubscription?.subscriptionId)?.order
                      : false
                  }
                  style={{
                    marginTop: '38px'
                  }}
                  onClick={() => {
                    if (currentSubscription?.subscriptionId === item.id && getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED') {
                      handleRenewSubscription()
                    } else {
                      handleCreateOrder(item)
                    }
                  }}
                >
                  {currentSubscription?.subscriptionId === item.id
                    ? getCurrentSubscriptionStatus(currentSubscription) === 'ACTIVE'
                      ? 'Current plan'
                      : getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED'
                        ? 'Renew'
                        : 'Activate'
                    : 'Choose'
                  }
                </Button>
              </Box>
            ))
          }
        </Box>
      </Box>

      <PaymentModal
        open={showPaymentModal.active}
        order={showPaymentModal.order}
        setOrder={(order) => {
          setShowPaymentModal({
            ...showPaymentModal,
            order: {
              ...showPaymentModal.order,
              ...order
            }
          })
        }}
        onRenewSubscription={() => {
          handleRenewSubscription();
        }}
        onClose={async () => {

          setShowPaymentModal({
            active: false, 
            order: null 
          })
        }}
      />
    </>
  );
};

export default Subscriptions;
