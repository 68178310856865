import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  deleteLibraryCampaign,
  deleteLibraryEmail,
  duplicateLibraryCampaign,
  duplicateLibraryEmail,
  getApprovedCampaigns,
  getLibraryCampaigns,
  getReviewLogCampaigns
} from '../../redux/services/my-library';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import CampaignTableRow from './components/CampaignTableRow';
import Filters from './components/Filters';
import { updateCampaign } from '../../redux/services/tactical-outreach';
import { getOnboardingCompleteness } from '../../redux/services/onboarding';
import { getCurrentSubscription } from '../../redux/services/subscriptions';
import { getCurrentSubscriptionStatus } from '../../helpers/subscription';
import { isStartUpTeamsSubscription } from '../../helpers/roles';
import { REVIEW_STATUS } from '../../utils/enums';
import { getProfile } from '../../redux/services/profile';
import { notify } from '../../providers/notification';
import { CampaignResponse, LibraryTabs } from '../../interfaces/campaign';
import { ProfileResponse } from '../../interfaces/profile';

import { useStyles } from './styles';
import { AccountSubscriptionResponse } from "../../interfaces/subscriptions";

const MyLibrary = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<(CampaignResponse & { expanded?: boolean })[] | null>(null);
  const [subscription, setSubscription] = useState<AccountSubscriptionResponse | null>(null);
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const [currentTab, setCurrentTab] = useState<LibraryTabs>(LibraryTabs.ALL);
  const [deleteSubject, setDeleteSubject] = useState<{ isCampaign: boolean, campaignId: string | number, emailId?: number } | null>(null);
  const [editSubject, setEditSubject] = useState<{ campaignId: string | number, value: string, field: string } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    handleIsSubscriptionActive();
    handleIsOnboardingCompleted();
    handleGetProfile();
  }, []);

  useEffect(() => {
    if (subscription) {
      setLoading(true);

      handleGetLibraryCampaigns();
    }
  }, [subscription, currentTab]);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (isStartUpTeamsSubscription(data)) {
      setCurrentTab(LibraryTabs.APPROVED);
    }

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }

    setSubscription(data);
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handleGetProfile = async () => {
    const { data } = await getProfile();

    setProfile(data);
  }

  const handleGetLibraryCampaigns = async () => {
    const method = currentTab === LibraryTabs.ALL
      ? getLibraryCampaigns
      : currentTab === LibraryTabs.APPROVED
        ? getApprovedCampaigns
        : () => getReviewLogCampaigns({
            type: 'user',
            reviewStatuses: [REVIEW_STATUS.DRAFT, REVIEW_STATUS.PENDING_REVIEW, REVIEW_STATUS.CHANGE_REQUIRED, REVIEW_STATUS.REJECTED]
          })

    const { data, error } = await method();

    if (!error && data && Array.isArray(data)) {
      let newCampaigns = [];

      if (currentTab === LibraryTabs.ALL || currentTab === LibraryTabs.APPROVED) {
        newCampaigns = [...data]
          .map(item => ({
            ...item,
            emails: [...item.emails].sort((a, b) => a.order - b.order),
            expanded: !!campaigns?.find(elem => elem.id === item.id)?.expanded
          }))
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      } else {
        newCampaigns = [...data]
          .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      }

      setCampaigns(newCampaigns);
    }

    setLoading(false);
  };

  const handleExpandCampaign = (event: any, id: string, value?: boolean) => {
    if (
      typeof event.target.className === 'string' &&
      !event.target.className.includes('MuiIconButton') &&
      !event.target.className.includes('MuiInputBase')
    ) {
      setCampaigns(campaigns.map(item =>
        item.id === id
          ? { ...item, expanded: value || !item.expanded }
          : item
      ));
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    if (deleteSubject.isCampaign) {
      const { error } = await deleteLibraryCampaign(`${deleteSubject.campaignId}`);

      if (!error) {
        notify.success('Campaign deleted successfully');
      } else {
        notify.error('Campaign deletion failed');
      }

      handleGetLibraryCampaigns();
    } else {
      const { error } = await deleteLibraryEmail({ campaignId: `${deleteSubject.campaignId}`, emailId: `${deleteSubject.emailId}` });

      if (!error) {
        notify.success('Email deleted successfully');

        handleGetLibraryCampaigns();
      } else {
        notify.error('Email deletion failed');
      }
    }

    setDeleteSubject(null);
    setLoading(false);
  };

  const handleSearchChange = (event) => {
    if (!isOnboardingCompleted || !isSubscriptionActive) {
      setShowActionBlockedModal(true);
    } else {
      const { value } = event.target;

      setSearchTerm(value);
      debouncedSearch(value);
    }
  };

  const debouncedSearch = useMemo(() => _.debounce((query) => {
    if (query) {
      const filtered = campaigns.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(query.toLowerCase())
      );
      setCampaigns(filtered);
    } else {
      handleGetLibraryCampaigns();
    }
  }, 300), [campaigns]);

  const handleSaveEdit = async () => {
    const { error } = await updateCampaign({
      body: {
        name: campaigns.find(item => item.id === editSubject.campaignId).name,
        description: campaigns.find(item => item.id === editSubject.campaignId).description,
        [editSubject.field]: editSubject.value
      },
      id: editSubject.campaignId
    });

    if (!error) {
      handleGetLibraryCampaigns();

      notify.success('Campaign edited successfully');
    } else {
      notify.error('Failed to edit');
    }

    setEditSubject(null);
  };

  const handleDuplicate = async ({ isCampaign, campaignId, emailId }) => {
    setLoading(true);

    if (isCampaign) {
      const { data, error } = await duplicateLibraryCampaign(campaignId);

      if (data && !error) {
        notify.success('Campaign duplicated successfully');

        handleGetLibraryCampaigns();
      } else {
        notify.error('Campaign duplication failed');

        setLoading(false);
      }
    } else {
      const { data, error } = await duplicateLibraryEmail({ campaignId, emailId });

      if (data && !error) {
        notify.success('Email duplicated successfully');

        handleGetLibraryCampaigns();
      } else {
        notify.error('Email duplication failed');

        setLoading(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '48px',
              fontWeight: '600',
              lineHeight: '58px',
              color: '#000000'
            }}
          >
            My History
          </Typography>
        </Box>

        <Filters
          searchTerm={searchTerm}
          subscription={subscription}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setCampaigns={setCampaigns}
          handleSearchChange={handleSearchChange}
        />

        <Box className={classes.content}>
          {campaigns && campaigns.length > 0
            ? <TableContainer
              component={Paper}
              style={{
                width: '100%',
                minWidth: '1070px',
                // maxWidth: '1080px',
                boxShadow: 'none',
                background: 'transparent',
                margin: '0 auto',
                // opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
                // pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        // width: '35%',
                        borderColor: 'transparent'
                      }}
                    />
                    <TableCell
                      align="left"
                      style={{
                        // width: '55%',
                        borderColor: 'transparent'
                      }}
                    />
                    {/* <TableCell
                      align="center"
                      style={{
                        // width: '20%',
                        borderColor: 'transparent'
                      }}
                    /> */}
                    <TableCell
                      align="right"
                      style={{
                        // width: '10%',
                        borderColor: 'transparent'
                      }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    position: 'relative'
                  }}
                >
                  {campaigns.map((item, index) => (
                    <React.Fragment key={index}>
                      <CampaignTableRow
                        item={item}
                        profile={profile}
                        subscription={subscription}
                        editSubject={editSubject}
                        isSubscriptionActive={isSubscriptionActive}
                        isOnboardingCompleted={isOnboardingCompleted}
                        currentTab={currentTab}
                        handleExpandCampaign={handleExpandCampaign}
                        setDeleteSubject={setDeleteSubject}
                        setEditSubject={setEditSubject}
                        handleSaveEdit={handleSaveEdit}
                        handleDuplicate={handleDuplicate}
                        setShowActionBlockedModal={setShowActionBlockedModal}
                      />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : <Box className={classes.notFound}>
              No campaigns
            </Box>
          }
        </Box>
      </Box>

      <ConfirmationModal
        open={!!deleteSubject}
        title={deleteSubject?.isCampaign
          ? 'Are you sure you want to delete campaign?'
          : 'Are you sure you want to delete email?'
        }
        description={deleteSubject?.isCampaign
          ? `Note that you'll delete this campaign forever and you will not be able to restore it.`
          : `Note that you'll delete this email forever and you will not be able to restore it.`
        }
        rejectBtnText={'Cancel'}
        confirmBtnText={'Delete'}
        onClose={() => setDeleteSubject(null)}
        onReject={() => setDeleteSubject(null)}
        onConfirm={() => {
          handleDelete();
          setDeleteSubject(null);
        }}
      />

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? props.history.push('/onboarding')
            : props.history.push('/subscriptions')
        }
      />
    </>
  );
};

export default MyLibrary;
