import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: "57.6px",
    marginTop: 40,

    "@media (max-width: 767.98px)": {
      fontSize: 28,
      lineHeight: "32px",
    },
  },
  block: {
    marginTop: 20,
  },
  outerPaper: {
    padding: 16,
    borderRadius: "28px",
    backgroundColor: "#F5F5F5",
    boxSizing: "border-box",

    "@media (min-width: 768px)": {
      padding: 20
    }
  },

  innerBlock: {},

  leftBlock: {
    display: "flex",
    marginBottom: 12,

    "@media (min-width: 768px)": {
      paddingRight: 26
    },

    "@media (min-width: 1200px)": {
      marginBottom: 0
    }
  },

  leftBlockTitle: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "21.6px",
    letterSpacing: "-0.005em",
    color: "#475569"
  },

  leftBlockDescription: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14.4px",
    textAlign: "left" as "left",
    color: "#475569",
    marginTop: 8
  },

  leftBlockContent: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between"
  },

  rightBlock: {},
}));