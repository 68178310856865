import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  promptSavingWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    // justifyContent: 'center',
    overflow: 'hidden',
    background: '#FAFAFA',
    zIndex: 9999999999,
    // '& svg': {
    //   width: '400px',
    //   height: '400px',
    // },
    // '& p': {
    //   fontSize: '48px', 
    //   fontWeight: '500', 
    //   lineHeight: '57px',
    // }
  },
  promptSavingContent: {
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    margin: '0 auto',
  },
  promptLabelDefault: {
    width: '100%',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    transition: 'all 0.5s ease-out',
  },
  promptLabelCurrent: {
    transform: 'translate(0px, 10px)',
    color: 'black',
    transitionDelay: '0.8s !important'
  },
  promptLabelPending: {
    transform: 'translate(0px, 30px)',
    color: '#475569'
  },
  promptLabelCompleted: {
    transform: 'translate(0px, 0px)',
    color: 'black',
    transitionDelay: '0.5s !important'
  }
}));