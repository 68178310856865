import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  multiSelectorWrapper: {
    maxWidth: "100%",
    display: "flex",
    gap: "7px"
  },
  chip: {
    width: "100%",
    height: "117px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    columnGap: "6px",
    cursor: "pointer",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    background: "#FFFFFF",
    boxShadow: "none",
    "& svg": {
      "& path": {
        fill: "#475569"
      }
    },
    "&:hover": {
      background: "#F1F5F9",
      border: "1px solid #3A84C9",
      boxShadow: "none"
    }
    // '& .MuiIconButton-label': {
    //   '& svg': {
    //     '& path': {
    //       fill: '#475569 !important'
    //     },
    //   },
    // }
  },
  chipSelected: {
    background: "#F1F5F9",
    border: "1px solid #3A84C9",
    boxShadow: "0px 0px 0px 1px #3A84C9",
    // '& svg': {
    //   '& path': {
    //     fill: '#3A84C9'
    //   },
    // },
    "&:hover": {
      background: "#F1F5F9",
      border: "1px solid #3A84C9",
      boxShadow: "0px 0px 0px 1px #3A84C9"
    }
    // '& .MuiIconButton-label': {
    //   '& svg': {
    //     '& path': {
    //       fill: '#0F7F75 !important'
    //     },
    //   },
    // }
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  bottomBox: {
    display: "flex",
    flexDirection: "column",
    gap: "2px"
  }
}));
