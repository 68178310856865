import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { trimTextByCharacters } from "../../../../helpers/text";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { getCampaignEmails, triggerEmailGenerate } from "../../../../redux/services/tactical-outreach";
import { observableService } from "../../../../services/observable";
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reload_icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as CommentsIcon } from '../../../../assets/icons/comments.svg';
import { ReactComponent as SideBarOpenIcon } from '../../../../assets/icons/right_bar_icon_2.svg';
import EmailsList from './components/EmailsList';
import CommentsList from './components/CommentsList';
import { REVIEW_STATUS } from "../../../../utils/enums";
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForEditor, showViewForReviewer } from "../../../../helpers/roles";
import { notify } from "../../../../providers/notification";
import { getCampaignComments } from "../../../../redux/services/my-library";
import { CampaignStatuses, CampaignSubCategory } from "../../../../interfaces/campaign";

import { useStyles } from "./styles";

const SideBar = ({
  open,
  email,
  campaign,
  profile,
  subscription,
  isSaveDisabled,
  setSideBarOpen,
  setEmail,
  setCampaign,
  setUnsavedDataModal,
  handleGetCampaign,
  setLoading,
  setShowStopGeneration,
}) => {
  const [tab, setTab] = useState<'emails' | 'comments'>('emails');
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [sideBarWidth, setSideBarWidth] = useState<number>(270);

  const history = useHistory();
  const classes = useStyles();

  const sideBarContent = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseOver]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseDown]);

  useEffect(() => {
    handleGetCamaignComments();
    handleGetCamaignEmails();

    scrollToBottom();
  }, [tab, open]);

  useEffect(() => {
    setSideBarWidth(270);
  }, [open]);

  const handleGetCamaignComments = async () => {
    const { data, error } = await getCampaignComments(campaign.id);

    if (data && !error) {
      setCampaign({
        ...campaign,
        comments: data
      });
    }
  }

  const handleGetCamaignEmails = async () => {
    const { data, error } = await getCampaignEmails(campaign.id);

    if (data && !error) {
      setCampaign({
        ...campaign,
        emails: data
      });
    }
  }

  const handleTriggerEmailGenerate = async () => {
    if (campaign.status === CampaignStatuses.INCOMPLETED) {
      setShowStopGeneration(true);
    } else {
      setLoading(true);

      const { data: email, error: emailError } = await triggerEmailGenerate({
        campaignId: campaign.id,
        type: campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS
          ? 'multi-touch'
          : 'single-touch'
      });

      if (email && !emailError) {
        observableService.sendEvent('Increase usage amount');

        handleGetCampaign(campaign.id, null);

        notify.success('Email created successfully');
      } else {
        if (emailError?.status === 402) {
          observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
        } else {
          notify.error('Failed to request AI');
        }

        observableService.sendEvent('Decrease usage amount');
      }

      setLoading(false);
    }
  }

  const handleAddNewComment = () => {
    if (!campaign.comments.find(item => item.status === "NEW")) {
      setCampaign({
        ...campaign,
        comments: [
          ...campaign.comments,
          {
            id: uuidv4(),
            status: "NEW",
          }
        ]
      })

      scrollToBottom();
    }
  }

  const scrollToBottom = () => {
    if (sideBarContent?.current) {
      setTimeout(() => {
        sideBarContent.current.scrollTo({
          top: sideBarContent.current.scrollHeight - 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const handleMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.id === 'resizeController') {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  }

  const handleMouseDown = (event) => {
    if (isMouseOver) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.id === 'resizeController') {
        setIsMouseDown(true);
      } else {
        setIsMouseDown(false);
      }
    }
  }

  const handleMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsMouseDown(false);
  }

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      const newWidth = window.innerWidth - event.x;

      if (newWidth >= 270 && newWidth <= window.innerWidth / 2) {
        setSideBarWidth(newWidth);
      }
    }
  }

  return (
    <>
      <Box
        className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}
        style={{
          background: tab === 'emails' ? '#CBD5E1' : '#F8FAFC',
          width: open ? sideBarWidth : '0',
          minWidth: open ? sideBarWidth : '0',
          maxWidth: open ? sideBarWidth : '0',
          transition: isMouseDown ? 'none' : 'all .4s',
        }}
      >
        <Box
          id={'resizeController'}
          className={classes.resizeController}
        />
        <Box className={classes.sideBarCloseButton}>
          {isStartUpTeamsSubscription(subscription) && campaign.status === CampaignStatuses.COMPLETED
            ? <Box className={classes.sideBarNavigationButtons}>
              <Box
                className={`${classes.sideBarNavigationButton} ${tab === 'emails' ? classes.sideBarNavigationButtonActive : ''}`}
                onClick={() => setTab('emails')}
              >
                <SideBarOpenIcon
                  height={20}
                />
              </Box>
              <Box
                className={`${classes.sideBarNavigationButton} ${tab === 'comments' ? classes.sideBarNavigationButtonActive : ''}`}
                onClick={() => setTab('comments')}
              >
                <CommentsIcon
                  height={20}
                />
              </Box>
            </Box>
            : <Box />
          }
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <div
          ref={sideBarContent}
          className={classes.sideBarContent}
        >
          {tab === 'emails'
            ? <EmailsList
              campaign={campaign}
              email={email}
              setEmail={setEmail}
              setUnsavedDataModal={setUnsavedDataModal}
              setLoading={setLoading}
              handleGetCampaign={handleGetCampaign}
              isSaveDisabled={isSaveDisabled}
            />
            : <CommentsList
              campaign={campaign}
              email={email}
              subscription={subscription}
              handleGetCampaign={handleGetCampaign}
              setCampaign={setCampaign}
            />
          }
        </div>

        {campaign.subCategory !== CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS && (
          <Button
            variant="contained"
            color="primary"
            disabled={
              tab === 'comments'
                ? false
                : !(campaign.createdBy === profile.user.uuid || showViewForAdmin(subscription))
            }
            startIcon={<>+</>}
            onClick={() => {
              tab === 'comments'
                ? handleAddNewComment()
                : handleTriggerEmailGenerate()
            }}
          >
            {tab === 'comments'
              ? 'Add comment'
              : 'New email'
            }
          </Button>
        )}
      </Box>
    </>
  );
};

export default SideBar;
